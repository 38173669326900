/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import Button from 'antd/es/button'
import dayjs from 'dayjs'
import { LoadingEnded, LoadingStart } from '../../../../utils/loading'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerModel extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      stdFieldType: [],
      fieldsModifyCurr: {},
      fieldsModifyLoading: false,
      fieldsModifyVisible: false,
      modelFields: []
    }
  }

  componentDidMount() {
    console.log(window.location)
    this.stdFieldTypeSync()
    this.tableDataSync(1)
  }


  /**
   * 模型字段加载
   * @param modelId
   */
  modelFieldsSync = (modelId) => {
    let that = this
    LoadingStart()
    apis.crawlerModelFieldList({
      model__id: modelId,
      pageSize: 10000
    }).then(resp => {
      LoadingEnded()
      that.setState({
        modelFields: resp.data.data.data
      })
    })
  }

  stdFieldTypeSync = () => {
    let that = this
    apis.crawlerFieldTypes().then(function(resp) {
      that.setState({
        stdFieldType: resp.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerModelList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '模型名称',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div style={{ fontSize: '22px' }}>{row.name}</div>
          )
        }
      },
      {
        title: '模型说明',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.brief}</div>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '创建时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{dayjs(row.created_at).format("YYYY-MM-DD hh:mm:ss")}</div>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        width: 150,
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   that.setState({
                     modelFields: [],
                     editCurr: data,
                     editShow: true
                   })
                   that.modelFieldsSync(row.id)
                 }} type={'link'}>编辑</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="数据模型管理" subTitle="数据模型设计器" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>创建数据模型</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'数据模型名称'} name={'name__contains'}>
                <ui.Input placeholder={'数据模型名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'采集数据模型管理'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerModelModifying(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'数据模型名称'} name={'name'}
                          rules={[{ required: true, message: '数据模型名称' }]}>
              <ui.Input placeholder={'数据模型名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'状态'} name={'state'}
                          rules={[{ required: true, message: '状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>

            <ui.Form.Item label={'数据模型说明'} name={'brief'}
                          rules={[{ required: true, message: '数据模型说明' }]}>
              <TextArea rows={6} placeholder={'数据模型说明'} />
            </ui.Form.Item>
            {that.state.editCurr.id &&
              <ui.Form.Item>
                <ui.Card bordered={false} styles={
                  {
                    body: {
                      padding: '0'
                    }
                  }
                }>
                  <ui.Table
                    size={'small'}
                    rowKey="id"
                    dataSource={that.state.modelFields}
                    columns={[
                      {
                        title: 'Id',
                        dataIndex: 'id'
                      },
                      {
                        title: '字段名称',
                        dataIndex: 'label'
                      },
                      {
                        title: '字段',
                        dataIndex: 'field'
                      },
                      {
                        title: '字段类型',
                        dataIndex: 'id',
                        render: function(value, row, index) {
                          console.log(row)
                          return (
                            <div>{row.field_type.field_type}</div>
                          )
                        }
                      },
                      {
                        title: '可用',
                        dataIndex: 'id',
                        render: function(value, row, index) {
                          return (
                            <ui.Button size={'small'} type={'primary'} shape={'circle'}>{row.state ? '是' : '否'}</ui.Button>
                          )
                        }
                      },
                      {
                        title: '管理',
                        width: 80,
                        fixed: 'right',
                        dataIndex: 'id',
                        render: function(value, row, index) {
                          console.log(row)
                          return (
                            <div>
                              <ui.Button type={'link'}>{row.state ? '禁用' : '启用'}</ui.Button>
                              <ui.Button type={'link'} onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                data.field_type = data.field_type.id
                                that.setState({
                                  fieldsModifyLoading: false,
                                  fieldsModifyCurr: data,
                                  fieldsModifyVisible: true
                                })
                              }}>编辑</ui.Button>
                            </div>
                          )
                        }
                      }
                    ]}
                    pagination={false} />
                </ui.Card>
                <ui.Card bordered={false}
                         actions={[
                           <ui.Button onClick={() => {
                             that.setState({
                               fieldsModifyCurr: {},
                               fieldsModifyLoading: false,
                               fieldsModifyVisible: true
                             })
                           }} type={'dashed'}>+ 新增字段</ui.Button>
                         ]}
                         styles={{ body: { padding: '8px' } }}>
                </ui.Card>
              </ui.Form.Item>
            }
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>

        <ui.Modal open={that.state.fieldsModifyVisible} title={'数据模型字段管理'} onCancel={() => {
          that.setState({
            fieldsModifyCurr: {},
            fieldsModifyLoading: false,
            fieldsModifyVisible: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            if (!that.state.editCurr.id) {
              ui.message.error('请先创建数据模型')
            }
            params.model = that.state.editCurr.id
            LoadingStart()
            if (that.state.fieldsModifyCurr.id) {
              params.id = that.state.fieldsModifyCurr.id
            }
            apis.crawlerModelFieldModifying(params).then(resp => {
              LoadingEnded()
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.modelFieldsSync(that.state.editCurr.id)
                that.setState({
                  fieldsModifyCurr: {},
                  fieldsModifyLoading: false,
                  fieldsModifyVisible: false,
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.fieldsModifyCurr}>
            <ui.Form.Item label={'字段名称'}
                          rules={[{ required: true, message: '字段名称' }]}
                          name={'label'}>
              <ui.Input placeholder={'字段名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'状态'} name={'state'}
                          rules={[{ required: true, message: '状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'字段 / 英文'}
                          rules={[{ required: true, message: '字段' }]}
                          name={'field'}>
              <ui.Input placeholder={'字段'} />
            </ui.Form.Item>
            <ui.Form.Item label={'字段类型'} name={'field_type'} rules={[{ required: true, message: '字段类型' }]}>
              <ui.Select placeholder={'字段类型'} style={{ width: '100%' }}>
                {that.state.stdFieldType.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.field_type}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}