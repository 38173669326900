import React,{Component} from "react";
import RouterView,{SSORouter} from "../routes/router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/fontawesome-free-solid"
import * as fa from "@fortawesome/fontawesome-free-solid"
import {Select,Dropdown,Badge,Spin,Menu,Button,Divider} from "antd";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import {createBrowserHistory } from "history"
import HttpPost from "../utils/request";
import { Loading } from "../utils/loading";
import { RouterProvider } from 'react-router-dom'
const {Option} = Select;
const history = createBrowserHistory()
const { SubMenu } = Menu;
export default class Layout extends Component{
    constructor(props) {
        super(props);
        this.state={
            extendList: [],
            NotifyCount: 0,
            menuList: [],
            productMenu: [],
            showSideBar: true,
            staf: {nickName: 'User'},
            extendMenu: [],
            collapsed: true,
            sidewidth: "46px",
            webPadding: "12px",
            siblingNavLinks: [],
            parentNode: null
        }
    };
    componentDidMount() {
        this.getUserMenu()
        this.getCurrStaff()
        this.appInitial()
    }

    /**
     * 应用初始化
     */
    appInitial () {
        var that = this
        var defaultSidebarWidth = localStorage.getItem("sidebar-width") ? localStorage.getItem("sidebar-width") : "46px"
        that.setState({
            sidewidth: defaultSidebarWidth
        })
        React.sidewidth = defaultSidebarWidth
        if (defaultSidebarWidth !== "46px") {
            that.setState({
                collapsed: false
            })
        }
    }
    /**
     * 当前登陆员工
     */
    getCurrStaff=()=>{
        var that = this
        HttpPost('system/getuser').then(function (resp) {
            that.setState({
                staf:resp.data.data
            })
        })
    }
    /**
     * 获得员工目录
     */
    getUserMenu = () =>{
        var that = this
        HttpPost("system/usermenu").then(function (res) {
            that.setState({
                menuList: res.data.data
            })
            setTimeout(() => {
                that.getParentNavNode()
            }, 200)
        })
    }
     /**
     *侧边栏状态修改
     */
    exchangeCollapsed = () => {
        var that = this
        var sidebarWidth = !that.state.collapsed ? "46px" : "256px"
        this.setState({
            collapsed: !that.state.collapsed,
            sidewidth: sidebarWidth
        })
        localStorage.setItem("sidebar-width", sidebarWidth)
        React.sidewidth = sidebarWidth
    }
    /**
     * 获得级目录
     * @returns {string|null}
     */
    getParentNavNode= ()=> {
        var that = this
        if (this.state.menuList === null || this.state.menuList.length === 0) {
            that.setState({
                parentNode: null
            })
            return
        }
        var currentUrl = window.location.pathname // 获取当前URL
        if (currentUrl === '/') {
            that.setState({
                parentNode: null
            })
            return
        }

        var parentNodeMapping = {}
        var NodeRouteMapping = {}
        var NodeIdMapping = {}
        that.state.menuList.forEach(item =>  {
            NodeRouteMapping[item.router] = item
            NodeIdMapping[item.id] = item
            if (item.top !== 0) {
                if (!parentNodeMapping[item.top]) {
                    parentNodeMapping[item.top] = []
                }
                if (!parentNodeMapping[item.top].includes(item.id)) {
                    parentNodeMapping[item.top].push(item)
                }
            }
        })

        if (NodeRouteMapping[currentUrl]) {
            var currentNode = NodeRouteMapping[currentUrl]
            if (currentNode.top !== 0) {
                var node =  {
                    currentNode: currentNode,
                    parentNode: NodeIdMapping[currentNode.top],
                    children: parentNodeMapping[currentNode.top]
                }
                console.log(node)
                that.setState({
                    parentNode: node
                })
                return
            }
        }
        that.setState({
            parentNode: null
        })
    }

    render() {
        var that = this
        var currpath = history.location.pathname;
        if (currpath === '/sso') {
            return (
                <div id="sign" style={{height:"100vh",width:"100vw",backgroundColor:"rgba(0,0,0,.7)",textAlign:"center", padding:"25vh"}}>
                    <Spin size="large" tip="Verifying Your Information..."/>
                    <SSORouter/>
                </div>
            )
        } else {
            return (
                <div id="app">
                    <div id="pc-web-header">
                        <ul id={"pc-web-header-left"}>
                            <li>
                                <FontAwesomeIcon icon={fa.faBars} onClick={this.exchangeCollapsed}/>
                            </li>
                            <li>
                                <a href="/" style={{textDecoration:'none'}}>
                                    <span style={{color:'#FFFFFF',fontSize:'18px',fontWeight:'600'}}>HOTTOL 河图洛书</span>
                                </a>
                            </li>
                            <li>
                                <Select defaultValue="中文简体" style={{ width: '160PX',borderRadius:'16px' }} size={"small"}>
                                    <Option value="zh_CN">中文简体</Option>
                                    <Option value="zh_Tw">中文繁体</Option>
                                    <Option value="en">ENGLISH</Option>
                                </Select>
                            </li>
                        </ul>
                        <div id={"top-center"} style={{flex: 1}}></div>
                        <div id={"top-right"}>
                            <Menu mode="horizontal" style={{backgroundColor:'rgba(250,250,250, 0)',borderBottom:'none',color:'#FFFFFF'}}>
                                <SubMenu title="费用与成本">
                                    <Menu.Item key="iamfinanceinvoice">
                                        <a href="/user/finance/bills" rel="noopener noreferrer">支付记录</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamorderlist">
                                        <a href="/user/finance/invoice" rel="noopener noreferrer">消费流水</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamfinancerecharge">
                                        <a href="/user/finance/recharge" rel="noopener noreferrer">在线充值</a>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu title="企业与组织">
                                    <Menu.Item key="iamcorpinfo">
                                        <a href="/Self/corp/info" rel="noopener noreferrer">企业管理</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamcorpstaf">
                                        <a href="/Self/corp/staf" rel="noopener noreferrer">员工管理</a>
                                    </Menu.Item>
                                    <Menu.Item key="corpbu">
                                        <a href="/Self/corp/bu" rel="noopener noreferrer">组织管理</a>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu title="帮助">
                                    <Menu.Item key="iamhelp">
                                        <a href="https://support.alaikis.com" target="_blank" rel="noopener noreferrer">帮助中心</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamworkorder">
                                        <a href="/case/workorder" rel="noopener noreferrer">创建工单</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamadvance">
                                        <a href="/case/advance" rel="noopener noreferrer">专家服务</a>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu title={'您好! ' + this.state.staf.nickName}>
                                    <Menu.Item key="iaminfo">
                                        <a href="/Services/Authorize/IAM/info" rel="noopener noreferrer">基本信息</a>
                                    </Menu.Item>
                                    <Menu.Item key="iamsecurity">
                                        <a href="/Services/Authorize/IAM/security" rel="noopener noreferrer">安全管理</a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button style={{color:'black',padding:'0'}} type="link" onClick={()=>{
                                            localStorage.removeItem("appToken");
                                            Loading()
                                            setTimeout(function () {
                                                window.location.reload()
                                            }, 5000)
                                        }}>退出登陆</Button>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item>
                                    <Badge count={this.state.NotifyCount} overflowCount={9} dot={true} size="mini" showZero>
                                        <FontAwesomeIcon icon={faBell} style={{fontSize:'16px'}}/>
                                    </Badge>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>

                    <div id="sidebar" style={{width: this.state.sidewidth}}>
                        <UI.Menu inlineCollapsed={this.state.collapsed}
                                 defaultOpenKeys={['8']}
                                 defaultSelectedKeys={['1']}
                                 mode="inline">
                            <SubMenu id="full-menu" key={'all-full-menu'} icon={<FontAwesomeIcon icon={fa.faGem}/>} title={that.state.collapsed ? '' : '服务导航'}  popupClassName="sidepopup">
                                {this.state.menuList.map(mn => {
                                    if (mn.top === 0 ) {
                                        return (
                                            <UI.Menu.ItemGroup className="full_menu_item" title={mn.name} key={mn.id}>
                                                {this.state.menuList.map(mnn => {
                                                    if(mnn.top === mn.id) {
                                                        return (
                                                            <UI.Menu.Item key={`${mn.id}-${mnn.id}`}>
                                                                <a href={mnn.redirect_url ? mnn.redirect_url : mnn.router}>{mnn.name}</a>
                                                            </UI.Menu.Item>
                                                        )
                                                    }
                                                })}
                                            </UI.Menu.ItemGroup>
                                        )
                                    }
                                })}
                            </SubMenu>
                            {this.state.menuList.map(item => {
                                if (item.top ===0) {
                                    return (
                                        <SubMenu key={item.id} icon={<FontAwesomeIcon icon={fa[item.icon]}/>} title={that.state.collapsed ?  '' :  item.name}>
                                            {that.state.menuList.map(sub=>{
                                                if(sub.top === item.id) {
                                                    return (
                                                        <UI.Menu.Item key={sub.id}>
                                                            <a href={sub.redirect_url ? sub.redirect_url : sub.router}>{sub.name}</a>
                                                        </UI.Menu.Item>
                                                    )
                                                }
                                            })}
                                        </SubMenu>
                                    )
                                }
                            })}
                        </UI.Menu>
                    </div>
                    {that.state.parentNode &&
                      <div id={"sub-service"} style={{
                          width: "var(--sub-service-width)",
                          position: "fixed",
                          left: React.sidewidth,
                          top: "var(--primary-head-height)"
                      }}>
                          <h2 style={{ fontWeight: 'bold', margin: "0 auto", fontSize: '1em' }}>
                              <i className={"fa fa-sitemap"} style={{marginRight: "8px"}}></i> {that.state.parentNode.parentNode.name}
                          </h2>
                          <UI.Divider />
                          <UI.Menu mode={"inline"} style={{ borderRight: "none" }}>
                              {that.state.parentNode.children.map((item, index) => {
                                  return <UI.Menu.Item key={index}><a href={item.router}>{item.name}</a></UI.Menu.Item>
                              })}
                          </UI.Menu>
                      </div>
                    }
                    <div id={"webapp-body"} style={that.state.parentNode ?
                      {
                          width: `calc(100vw - ${this.state.sidewidth} - var(--sub-service-width))`,
                          position: "fixed",
                          left: `calc(${this.state.sidewidth} +  var(--sub-service-width))`,
                          padding: this.state.webPadding
                      }
                      :
                      {
                          width: `calc(100vw - ${this.state.sidewidth})`,
                          position: "fixed",
                          left: this.state.sidewidth,
                          padding: this.state.webPadding
                      }

                    }>
                        <RouterProvider router={RouterView}></RouterProvider>
                    </div>
                </div>
            )
        }
    }
}
