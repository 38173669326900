/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { LoadingEnded, LoadingStart } from '../../../../utils/loading'
import Button from 'antd/es/button'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerRule extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      stdFieldType: [],
      engines: []
    }
  }

  componentDidMount() {
    this.stdFieldTypeSync()
    this.gatherEngineSync()
    this.tableDataSync(1)
  }



  editRef = React.createRef()
  /**
   * 获取采集源
   * @param keywords
   */
  gatherEngineSync = (keywords) => {
    let that = this
    let para = {}
    para.pageNumber = 1
    para.pageSize = 20
    if (keywords) {
      para.name__icontains=keywords
    }
    LoadingStart()
    apis.crawlerSourceList(para).then(function(resp) {
      LoadingEnded()
      that.setState({
        engines: resp.data.data.data,
      })
    })
  }

  /**
   * 获取标准字段
   */
  stdFieldTypeSync = () => {
    let that = this
    apis.crawlerFieldTypes().then(function(resp) {
      that.setState({
        stdFieldType: resp.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerFieldList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }



  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '开放规则',
        key: 'id',
        align: 'center',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'circle'} type={'primary'} size={'small'} style={{backgroundColor:"darkcyan"}}>{row.public ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '字段名称',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.name}</div>
          )
        }
      },
      {
        title: '数据字段',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.field}</div>
          )
        }
      },
      {
        title: '字段类型',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.field_type.field_type}</div>
          )
        }
      },
      {
        title: '数据源',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.engine.domain}</div>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        align: 'center',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'circle'} type={'primary'} size={'small'} style={{backgroundColor: "tomato"}}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        width: 80,
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   data.engine = data.engine.id
                   data.field_type = data.field_type.id
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="采集规则管理" subTitle="采集规则新增，禁用" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>新增采集规则</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'采集规则名称'} name={'name__contains'}>
                <ui.Input placeholder={'采集规则名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'云采集采集规则器'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerFieldModify(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr} ref={that.editRef}>
            <ui.Form.Item label={'采集规则名称'} name={'name'}
                          rules={[{ required: true, message: '字段名称' }]}>
              <ui.Input placeholder={'字段名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'采集规则可用状态'} name={'state'}
                          rules={[{ required: true, message: '采集源状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'数据字段'} name={'field'}
                          help={"英文下划线或则驼峰"}
                          rules={[{ required: true, message: '数据字段' }]}>
              <ui.Input placeholder={'数据字段'} />
            </ui.Form.Item>
            <ui.Form.Item label={'采集源'} name={'engine'} rules={[{ required: true, message: '采集源' }]}>
              <ui.Select showSearch={true} onSearch={keyword => {
                that.gatherEngineSync(keyword)
              }} placeholder={'采集源'} style={{ width: '100%' }}>
                {that.state.engines.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name} / {item.domain}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            <ui.Form.Item label={'解析引擎'} name={'compile_engine'}
                          rules={[{ required: true, message: '解析引擎' }]}>
              <ui.Radio.Group>
                <ui.Radio value={"bs4"}>bs4</ui.Radio>
                <ui.Radio value={"xpath"}>xpath</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'字段类型'} name={'field_type'} rules={[{ required: true, message: '字段类型' }]}>
              <ui.Select placeholder={'字段类型'} onChange={v => {
                that.editRef.current.setFieldsValue({
                  field_type: v
                })
              }} style={{ width: '100%' }}>
                {that.state.stdFieldType.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.field_type}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>

            <ui.Form.Item label={'随机中停时间'} name={'sleep_range'}
                          help={"对于有防爬机制的网站适用，格式:3,6即随机中停3至6秒,未配置则不中停"}
                          rules={[{ required: false, message: '随机中停时间' }]}>
              <ui.Input placeholder={'随机中停时间'} />
            </ui.Form.Item>

            <ui.Form.Item label={'取值范围'} name={'range'}
                          help={"对于列表类型，如属性列表或则图片列表来说，即为取值的数量限制，如2,7则是取第二至第六条数据，2,则是取第二条数据开始,,6则是取从开始至第五条数据"}
                          rules={[{ required: false, message: '取值范围' }]}>
              <ui.Input placeholder={'取值范围'} />
            </ui.Form.Item>

            <fieldset>
              <legend>数据清理规则</legend>
              <ui.Form.Item label={'头尾清理'} name={'strip'}
                            help={'如" "则为去除头性的空格，"\n"则为去除换行符'}
                            rules={[{ required: false, message: '取值范围' }]}>
                <ui.Input placeholder={'取值范围'} />
              </ui.Form.Item>
              <ui.Form.Item label={'自动后缀'} name={'suffix'}
                            help={'自动为结果加后缀，如货币，自动加USD格式化'}
                            rules={[{ required: false, message: '自动后缀' }]}>
                <ui.Input placeholder={'自动后缀'} />
              </ui.Form.Item>
              <ui.Form.Item label={'排除键'} name={'exclude_keys'}
                            rules={[{ required: false, message: '排除键' }]}>
                <ui.Input placeholder={'排除键'} />
              </ui.Form.Item>
            </fieldset>


            <ui.Form.Item label={"取值规则"} name={'value_type'}
                          rules={[{ required: true, message: '取值规则' }]}>
              <ui.Radio.Group>
                <ui.Radio value={"primary"}>内含内容</ui.Radio>
                <ui.Radio value={"attribute"}>element参数</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>

            <fieldset>
              <legend>属性采集参数</legend>
              <ui.Form.Item label={'属性键值对分隔符'} name={'strip'}
                            help={'如:"年份：2024"这样的格式则"："即为其分隔符'}
                            rules={[{ required: false, message: '属性键值对分隔符' }]}>
                <ui.Input placeholder={'属性键值对分隔符'} />
              </ui.Form.Item>
              <ui.Form.Item label={'属性键值取值目标节点'} name={'key_target'}
                            rules={[{ required: false, message: '属性键值取值目标节点' }]}>
                <ui.Input placeholder={'属性键值取值目标节点'} />
              </ui.Form.Item>
              <ui.Form.Item label={'属性键值取值属性'} name={'key_value_attr'}
                            help={'若取值类型非primary则必填'}
                            rules={[{ required: false, message: '属性键值取值属性' }]}>
                <ui.Input placeholder={'属性键值取值属性'} />
              </ui.Form.Item>
              <ui.Form.Item label={'属性值清洗代码'} name={'value_script'}
                            help={'使用python对已经取值的结果进行加工'}
                            rules={[{ required: false, message: '属性值清洗代码' }]}>
                <TextArea rows={6} placeholder={'属性值清洗代码'} />
              </ui.Form.Item>
            </fieldset>

            <ui.Form.Item label={'目标容器'} name={'node'}
                          help={"若为jsonApi,则可用值为: params,targetData,item,originData"}
                          rules={[{ required: false, message: '目标容器' }]}>
              <ui.Input placeholder={'目标容器'} />
            </ui.Form.Item>

            <ui.Form.Item label={'取值目标'} name={'target'}
                          rules={[{ required: true, message: '取值目标' }]}>
              <ui.Input placeholder={'取值目标'} />
            </ui.Form.Item>

            <ui.Form.Item label={'取值属性'} name={'value_attr'}
                          help={'若取值类型非primary则必填'}
                          rules={[{ required: false, message: '取值属性' }]}>
              <ui.Input placeholder={'取值属性'} />
            </ui.Form.Item>

            <ui.Form.Item label={'取值格式化代码'} name={'formatter_script'}
                          help={'若普通清理无法达到目标格式，则可使用python脚本对数据进行进一步清洗'}
                          rules={[{ required: false, message: '取值格式化代码' }]}>
              <TextArea rows={6} placeholder={'取值格式化代码'} />
            </ui.Form.Item>


            <ui.Form.Item label={'属性键值取值标准'} name={'value_attr'}
                          help={'若取值类型非primary则必填'}
                          rules={[{ required: false, message: '取值属性' }]}>
              <ui.Radio.Group>
                <ui.Radio value={'primary'}>内含内容</ui.Radio>
                <ui.Radio value={'attribute'}>element参数</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>

            <ui.Form.Item label={'目标值处理脚本'} name={'exec_script'}
                          help={'使用python对已经取值的结果进行加工'}
                          rules={[{ required: false, message: '目标值处理脚本' }]}>
              <TextArea rows={6} placeholder={'目标值处理脚本'} />
            </ui.Form.Item>

            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}