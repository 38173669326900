/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/9/12
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from '../../../../utils/request'
import dayjs from 'dayjs'
import {LoadingEnded, LoadingStart} from "../../../../utils/loading";


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class DatabaseBackup extends Component {
    constructor() {
        super()
        this.state = {
            tableData: [],
            paging: { total: 0, pageNumber: 1, pageSize: 20 },
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            availableAuthorize: [],
            availableDatabases: [],
            schedulerGroupList: []
        }
    }

    componentDidMount() {
        this.availableAuthorizeSync()
        this.schedulerGroupSync()
        this.tableDataSync(1)
    }

    /**
     * 可用任务分组
     */
    schedulerGroupSync () {
        var param = {}
        param.pageNumber = 1
        param.pageSize = 2000
        param.state = true
        var that = this
        apis.schedulerGroups(param).then(res => {
            that.setState({
                schedulerGroupList: res.data.data.data
            })
        })
    }

    /**
     * 加载执行分组
     */
    availableAuthorizeSync() {
        var param = {}
        param.pageNumber = 1
        param.pageSize = 2000
        param.state = true
        param.grant__systemGroup__code = 'database'
        var that = this
        apis.getAuths(param).then(res => {
            that.setState({
                availableAuthorize: res.data.data.data
            })
        })
    }

    /**
     * 获得授权节点数据库列表
     * @param grantId
     */
    getDatabaseList(grantId) {
        LoadingStart()
        var that = this
        apis.getTargetGrantDatabaseList(grantId).then(res => {
            LoadingEnded()
            that.setState({
                availableDatabases: res.data.data
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function() {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this
        let para = {}
        para.pageNumber = pageNumber
        para.pageSize = 20
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.backupTaskList(para).then(function(resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: '通道名称',
                key: 'id',
                render: function(value, row, index) {
                    return row.name
                }
            },
            {
                title: '授权节点',
                key: 'id',
                render: function(value, row, index) {
                    return <ui.Button shape={'round'}>{row.authorize_channel.name}</ui.Button>
                }
            },
            {
                title: '创建时间',
                key: 'id',
                render: function(value, row, index) {
                    return dayjs(row.created_time).format('YYYY-MM-DD HH:mm:ss')
                }
            },
            {
                title: '可用',
                key: 'id',
                render: function(value, row, index) {
                    return (
                        <ui.Button shape={'circle'} size={'small'} type={'primary'} danger={true}>{row.state ? '是' : '否'}</ui.Button>
                    )
                }
            },
            {
                title: '管理',
                key: 'id',
                fixed: 'right',
                render: function(value, row, index) {
                    return (
                        <span>
                 <ui.Button onClick={() => {
                     var data = JSON.parse(JSON.stringify(row))
                     data.db_list = data.db_list.split(',')
                     data.scheduler_group_id = row.scheduler.id
                     data.authorize_channel_id = row.authorize_channel.id
                     data.scheduler_formula = row.scheduler.scheduler_standard
                     that.getDatabaseList(data.authorize_channel.id)
                     that.setState({
                         editCurr: data,
                         editShow: true
                     })
                 }} type={'link'}>编辑</ui.Button>
            </span>
                    )
                }
            }
        ]

        return (
            <div>
                <PageHeader title="数据库备份" subTitle="数据库定时备份系统" extra={[
                    <ui.Button key="1" type="primary" shape="round" onClick={() => {
                        that.setState({
                            editCurr: {scheduler_formula: "01 30 3 * *"},
                            editShow: true,
                            submitting: false
                        })
                    }}>新增备份</ui.Button>
                ]} />
                <div style={{ color: 'darkcyan', fontSize: '10px', marginBottom: '16px' }}>
                    <BellOutlined /> 系统按日均备份次数以及存储量计费，20次，1GB免费
                </div>
                <ui.Card>
                    <div style={{ marginTop: '22px' }}>
                        <ui.Form onFinish={that.makeSearch} layout={'inline'}>
                            <ui.Form.Item label={'通道名称'} name={'name__contains'}>
                                <ui.Input placeholder={'通道名称'} />
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </div>
                </ui.Card>

                <div style={{ marginTop: '22px' }}>
                    <ui.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }} />
                </div>

                <ui.Modal open={that.state.editShow} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <h2>数据库备份任务管理</h2>
                    <small>配置备份的数据库存列表以及选择需备份的数据库</small>
                    <ui.Divider/>
                    <ui.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        if (params.db_list) {
                            params.db_list = params.db_list.join(",")
                        }
                        apis.handleDbBackupPlan(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200) {
                                ui.message.success('提交信息成功')
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                ui.message.error(resp.data.msg)
                            }
                        })
                    })} labelCol={{span: 24}} labelAlign={'left'} initialValues={that.state.editCurr}>
                        <ui.Form.Item label={'备份任务名称'} name={'name'}
                                      rules={[{required: true, message: '备份通道名称'}]}>
                            <ui.Input placeholder={'备份任务名称'}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={'备份通道状态'} name={'state'}
                                      rules={[{required: true, message: '备份通道状态'}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>可用</ui.Radio>
                                <ui.Radio value={false}>禁用</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={'授权终端'} name={'authorize_channel_id'}
                                      rules={[{required: true, message: '授权终端'}]}>
                            <ui.Select disabled={that.state.editCurr.id} placeholder={'授权终端'} style={{width: '100%'}} onChange={v => {
                                that.setState({
                                    availableDatabases: []
                                })
                                that.getDatabaseList(v)
                            }}>
                                {that.state.availableAuthorize.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        {that.state.availableDatabases.length > 0 &&
                            <ui.Form.Item label={'备份数据库存列表'} name={'db_list'}
                                          rules={[{required: true, message: '授权终端'}]}>
                                <ui.Checkbox.Group placeholder={'备份数据库存列表'} style={{width: '100%'}}>
                                    {that.state.availableDatabases.map(item => {
                                        return (
                                            <ui.Checkbox value={item} key={item}>{item}</ui.Checkbox>
                                        )
                                    })}
                                </ui.Checkbox.Group>
                            </ui.Form.Item>
                        }

                        <ui.Form.Item label={'适用规则分组'} name={'scheduler_group_id'}
                                      rules={[{required: true, message: '适用规则分组'}]}>
                            <ui.Select disabled={that.state.editCurr.id} placeholder={'授权终端'} style={{width: '100%'}}>
                                {that.state.schedulerGroupList.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>{item.title}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={'计划规则'} name={'scheduler_formula'}
                                      help={"如每3小时:25 32 */3 * *,默认每日3点备份"}
                                      rules={[{required: true, message: '计划规则'}]}>
                            <ui.Input disabled={that.state.editCurr.id} placeholder={'计划规则'}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={'通道说明'} name={'brief'}
                                      rules={[{required: true, message: '通道说明'}]}>
                            <TextArea rows={8} placeholder={'计划备注'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'}
                                       block>立即提交
                            </ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}