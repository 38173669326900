/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import FormatUtils from '../../../../utils/FormatUtils'
import { json } from 'react-router-dom'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerEngine extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      executeGroup: [],
      gatherType: []
    }
  }

  componentDidMount() {
    this.gatherTypeSync()
    this.tableDataSync(1)
  }


  /**
   * 采集类型加载
   */
  gatherTypeSync() {
    var that = this
    apis.crawlerTypeList({}).then(res => {
      that.setState({
        gatherType: res.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerSourceList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        key: 'id',
        render: function(value, row, index) {
          return <img style={{borderRadius: "16px",width:"32px",height:"32px"}} src={row.icon}/>
        }
      },
      {
        title: '源名称',
        key: 'id',
        width: "40%",
        render: function(value, row, index) {
          return (
            <div>
              <h2 style={{fontSize: "28px"}}>{row.name}</h2>
              <small>{row.brief}</small>
            </div>
          )
        }
      },
      {
        title: '源域名',
        key: 'id',
        render: function(value, row, index) {
          return row.domain
        }
      },
      {
        title: '采集类型',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.gather_type.name}</div>
          )
        }
      },
      {
        title: '采集器',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'dashed'} size={"small"}>{row.spider}</ui.Button>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        fixed: 'right',
        width: '150px',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   data.gather_type = data.gather_type.id
                   if (data.meta) {
                     data.meta = JSON.parse(data.meta)
                     data = FormatUtils.FormDataJsonEncoder(data,["meta"])
                   }
                   console.log(data)
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
              <ui.Button type={'link'} href={row.gate_url} target={"_blank"}>立即访问</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="采集源管理" subTitle="统一管理采集数据源" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {target_type: "single"},
              editShow: true,
              submitting: false
            })
          }}>新增采集源</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'采集源名称'} name={'name__contains'}>
                <ui.Input placeholder={'采集源名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'定时任务采集源名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            params = FormatUtils.FormDataJsonDecoder(params)
            if (params.meta) {
              params.meta = JSON.stringify(params.meta)
            }
            apis.crawlerSourceModify(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'采集源名称'} name={'name'}
                          rules={[{ required: true, message: '采集源名称' }]}>
              <ui.Input placeholder={'采集源名称'} />
            </ui.Form.Item>

            {that.state.editCurr.id &&
              <ui.Form.Item label={'源简介'} name={'brief'}
                            rules={[{ required: false, message: '源简介' }]}>
                <TextArea rows={6} placeholder={'源简介'} />
              </ui.Form.Item>
            }
            <ui.Form.Item label={'取值类型'} name={'target_type'}
                          rules={[{ required: true, message: '采集源状态' }]}>
              <ui.Radio.Group onChange={e => {
                var tmp = that.state.editCurr
                tmp.target_type = e.target.value
                that.setState({
                  editCurr: tmp
                })
              }}>
                <ui.Radio value={'single'}>单条记录</ui.Radio>
                <ui.Radio value={'list'}>记录列表</ui.Radio>
                <ui.Radio value={'fieldRepeat'}>单字段循环</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            {that.state.editCurr.target_type === 'list' &&
              <ui.Card title={"列表采集取值参数"} style={{ backgroundColor: '#F0F8FF' }} styles={{
                body: { padding: '6px' }
              }}>
                <ui.Form.Item label={'列表父节点'} name={'meta__list_container'}
                              rules={[{ required: false, message: '列表父节点' }]}>
                  <ui.Input placeholder={'列表父节点'} />
                </ui.Form.Item>
                <ui.Form.Item label={'列表节点'} name={'meta__list_node'}
                              rules={[{ required: true, message: '列表节点' }]}>
                  <ui.Input placeholder={'列表节点'} />
                </ui.Form.Item>
              </ui.Card>
            }
            {that.state.editCurr.target_type === 'fieldRepeat' &&
              <ui.Form.Item label={'循环字段名称'} name={'meta__repeat_field'}
                            rules={[{ required: true, message: '循环字段名称' }]}>
                <ui.Input placeholder={'循环字段名称'} />
              </ui.Form.Item>
            }
            <ui.Form.Item label={'采集类型'} name={'gather_type'} rules={[{ required: true, message: '采集类型' }]}>
              <ui.Select placeholder={'选择使用的采集类型'} style={{ width: '100%' }} onChange={e => {
                var tmp = that.state.editCurr
                tmp.gather_type = e
                that.setState({
                  editCurr: tmp
                })
              }}>
                {that.state.gatherType.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            {that.state.editCurr.gather_type === 1 &&
              <ui.Card title={'json请求参数'} style={{ backgroundColor: '#F0F8FF' }} styles={{
                body: { padding: '6px' }
              }}>
                <ui.Form.Item label={'操作前处理'} name={'meta__before_request'}
                              help={'python代码，传入参数为url视角,对url处理后会返回新的json对像,入参参数为:\"params\"'}
                              rules={[{ required: false, message: '操作前处理' }]}>
                  <TextArea rows={6} placeholder={'api地址'} />
                </ui.Form.Item>
                <ui.Form.Item label={'httpclient创建后'} name={'meta__after_client_initialize'}
                              help={'python代码，客户端传入参数为\"httpClient\"'}
                              rules={[{ required: false, message: '操作前处理' }]}>
                  <TextArea rows={6} placeholder={'api地址'} />
                </ui.Form.Item>
                <ui.Form.Item label={'请求完成后'} name={'meta__after_request'}
                              help={'python代码，可使用originData参数'}
                              rules={[{ required: false, message: '请求完成后' }]}>
                  <TextArea rows={6} placeholder={'api地址'} />
                </ui.Form.Item>
                <ui.Form.Item label={'api地址'} name={'meta__api_url'}
                              help={'支持python format规则，可使用配置属性值'}
                              rules={[{ required: true, message: 'api地址' }]}>
                  <ui.Input placeholder={'api地址'} />
                </ui.Form.Item>

                <ui.Form.Item label={'api请求方式'} name={'meta__api_method'}
                              rules={[{ required: true, message: 'api请求方式' }]}>
                  <ui.Radio.Group>
                    <ui.Radio value={'get'}>get</ui.Radio>
                    <ui.Radio value={'post'}>post</ui.Radio>
                  </ui.Radio.Group>
                </ui.Form.Item>
                <ui.Form.Item label={'api参数'} name={'meta__api_arguments'}
                              help={'json格式:{"name":"value"}'}
                              rules={[{ required: false, message: 'api参数' }]}>
                  <TextArea rows={4} placeholder={'api参数'} />
                </ui.Form.Item>
                <ui.Form.Item label={'请求参数'} name={'meta__api_request_params'}
                              help={'json格式:{"name":"value"}'}
                              rules={[{ required: false, message: '请求参数' }]}>
                  <TextArea rows={4} placeholder={'请求参数'} />
                </ui.Form.Item>
                <ui.Form.Item label={'签名函数'} name={'meta__api_sign_func'}
                              rules={[{ required: false, message: '签名函数' }]}>
                  <ui.Input placeholder={'签名函数'} />
                </ui.Form.Item>

                <ui.Form.Item label={'请求头'} name={'meta__api_request_headers'}
                              help={'json格式:{"name":"value"}'}
                              rules={[{ required: false, message: '请求头' }]}>
                  <ui.Input placeholder={'请求头'} />
                </ui.Form.Item>


                <ui.Form.Item label={'额外请求参数'} name={'meta__api_extend_arguments'}
                              help={'json格式:如{"verify":false,"timeout":120}'}
                              rules={[{ required: false, message: '额外请求参数' }]}>
                  <ui.Input placeholder={'额外请求参数'} />
                </ui.Form.Item>

              </ui.Card>
            }
            <ui.Form.Item label={'采集源状态'} name={'state'}
                          rules={[{ required: true, message: '采集源状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'访问器'} name={'spider'}
                          rules={[{ required: true, message: '"Merchant", this.GetSignUser().Merchant.Id' }]}>
              <ui.Radio.Group>
                <ui.Radio value={"httpGet"}>http请求</ui.Radio>
                <ui.Radio value={"browser"}>浏览器访问</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'基础URL'} name={'base_url'} help="地址样式:https://www.baidu.com"
                          rules={[{ required: false, message: '基础URL' }]}>
              <ui.Input placeholder={'基础URL'} />
            </ui.Form.Item>
            <fieldset>
              <legend style={{ fontSize: '12px', color: '#ccc' }}>以下为列表采集规则，未配置则不自动采集列表</legend>
              <ui.Form.Item label={'下级目录拾取器'} name={'sub_directory'}
                            rules={[{ required: false, message: '下级目录拾取器' }]}>
                <TextArea rows={6} placeholder={'下级目录拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'详情页拾取器'} name={'page_rule'}
                            rules={[{ required: false, message: '详情页拾取器' }]}>
                <TextArea rows={6} placeholder={'详情页拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'页面入口地址'} name={'gate_url'}
                            rules={[{ required: true, message: '页面入口地址' }]}>
                <ui.Input placeholder={'页面入口地址'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点拾取器'} name={'gate_node'}
                            rules={[{ required: false, message: '入口节点拾取器' }]}>
                <TextArea rows={6} placeholder={'入口节点拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点取值范围'} name={'gate_range'}
                            rules={[{ required: false, message: '入口节点取值范围' }]} help={'如：1,6'}>
                <TextArea rows={6} placeholder={'入口节点取值范围'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点目标拾取器'} name={'gate_target'}
                            rules={[{ required: false, message: '入口节点目标拾取器' }]} help={'如：1,6'}>
                <TextArea rows={6} placeholder={'入口节点目标拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接节点拾取器'} name={'link_node'}
                            rules={[{ required: false, message: '入口连接节点拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接节点拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接拾取器'} name={'link_target'}
                            rules={[{ required: false, message: '入口连接拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接目标拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接名称拾取器'} name={'link_name'}
                            rules={[{ required: false, message: '入口连接名称拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接名称拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'休停规则'} name={'sleep_range'}
                            rules={[{ required: false, message: '休停规则' }]} help={'如:2,6休停2到6秒'}>
                <ui.Input placeholder={'休停规则'} />
              </ui.Form.Item>
              <ui.Form.Item label={'最大页数'} name={'max_page_size'}
                            rules={[{ required: false, message: '最大页数' }]} help={'如:2,6休停2到6秒'}>
                <ui.InputNumber style={{ width: "100%" }} placeholder={'最大采集页数'} />
              </ui.Form.Item>
            </fieldset>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}