import axios from 'axios';

let ssoUrl = "https://graph.hottol.com/sign"
let apiUrl = "https://api.hottol.com/iam"
const GWAPIUrl = "https://api.hottol.com/op"
if (process.env.NODE_ENV==="development") {
    // ssoUrl = 'http://htapi.appify.cn/home/sign';
    apiUrl = 'http://127.0.0.1:6688';
}
export var BaseUrl = {
    SSO: ssoUrl,
    API: apiUrl
}
export var UploadProps = {
    action: apiUrl + '/system/upload',
    multiple: true,
    headers: {
        Authorization: 'AppToken ' + localStorage.getItem('appToken')
    }
}

function getSignUrl() {
    var queryParams= window.location.search
    var redirectPath = `${window.location.pathname}${queryParams ?? ''}`
    return `${ssoUrl}?redirectUrl=${encodeURI(redirectPath)}`
}

axios.interceptors.request.use(
    config => {
        let appToken = localStorage.getItem('appToken')
        let currPath = window.location.pathname
        if (!appToken && currPath!=='/sso') {
            window.location.href = getSignUrl()
        } else {
            if (appToken) {
                config.headers.Authorization = 'AppToken ' + appToken
            }
            return config
        }
    },
    err => {
        return Promise.reject(err)
    }
)

axios.interceptors.response.use(
    response => {
        let currPath = window.location.pathname
        if (response.data.code === 900 && currPath!=='/sso') {
            window.location.href = getSignUrl()
        } else {
            return response
        }
    }
)
const HttpPost = (url, params = null) => {
    var api = url;
    if (url.indexOf('http') === -1) {
        api = `${apiUrl}/${url}`
    }
    return axios.post(api, params)
}

const GWPost = (url, params = null) => {
    url = `${GWAPIUrl}${url}`
    return HttpPost(url, params)
}

const GWAPI = {
    /**
     * 获得国家列表
     * @param {*} filter 
     * @returns 
     */
    getCountries: function (filter) {
        return GWPost("/open/country/list", filter)
    },
    /**
     * 语言列表
     * @param {*} filter 
     * @returns 
     */
    getCurrencies: function (filter) {
        return GWPost("/open/currency/list", filter)
    },
    /**
     * 全球语言列表
     * @param {*} filter 
     * @returns 
     */
    getLanguages: function (filter) {
        return GWPost("/open/language/list", filter)
    },
    /**
     *常见计量单位
     * @param {*} filter 
     * @returns 
     */
    getUnits: function (filter) {
        return GWPost("/open/unit/list", filter)
    }
}

export default HttpPost
export {GWAPI}
