


var FormatUtils = {
  /**
   * convert the table json data to json tree
   * @param param
   */
  FormDataJsonDecoder: function (param) {
    var data = {}
    Object.keys(param ? param : {}).forEach(item=>{
      var currentValue =param[item]
      if (item.includes("__")) {
        var keysTree = item.split("__")
        var tableField = keysTree[0]
        var valueField = keysTree.slice(1).join("__")
        data[tableField] = data[tableField] ? data[tableField] : {}
        data[tableField][valueField] = currentValue //多层后续可考虑
      } else {
        data[item] = currentValue
      }
    })
    return data
  },
  /**
   * convert json tree to table json data
   * @param param
   * @param fields
   */
  FormDataJsonEncoder: function (param, fields) {
    fields.forEach(field=>{
      param[field] = param[field] ? param[field] : {}
      Object.keys(param[field]).forEach(currentKey=>{
        var newKey = `${field}__${currentKey}`
        param[newKey] = param[field][currentKey]
      })
      delete  param[field]
    })
    return param
  },
}

export default FormatUtils